.react-calendar {
  width: 100%;
  max-width: 350px;
  background: white;
  border: 1px solid transparent;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__tile--now {
  background: #588eb5aa;
  color: white;
  border-radius: 0.25rem;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #588eb5;
  border-radius: 0.25rem;
  color: white;
}

.react-calendar__year-view .react-calendar__tile {
  padding: 0.5rem;
}
